import React from 'react';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import OrderDetail from 'rev.sdk.js/Templates/OrderPage/OrderDetail';
import styled from 'styled-components';
import qs from 'query-string';
import config from '../../../data.json';
import withPageEntry from '../../withPageEntry';
import * as CustomCheckout from '../../Utils/CustomCheckout';
import * as AppActions from '../../AppActions';

function AnonymousOrderDetail(props) {
  const params = qs.parse(props.location?.search);
  const [order, setOrder] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let _order = null;

      if (params.order_id) {
        _order = await ApiUtil.req(
          `${config.apiHost}/order/anonymous/detail?collection=order&order_id=${params.order_id}&token=${params.tmp_token}`,
        );
      } else {
        _order = await ApiUtil.req(
          `${config.apiHost}/order/anonymous/detail?collection=order&order_number=${params.order_number}&token=${params.tmp_token}`,
        );

        if (!_order) {
          _order = await ApiUtil.req(
            `${config.apiHost}/order/anonymous/detail?collection=checkout&order_number=${params.order_number}&token=${params.tmp_token}`,
          );
        }
      }

      _order.id = _order._id.$oid;
      setOrder(_order);
    } catch (ex) {}
    AppActions.setLoading(false);
  }, [params.order_id, params.order_number, params.tmp_token]);

  React.useEffect(() => {
    if ((params.order_id || params.order_number) && params.tmp_token) {
      fetchData();
    }
  }, [fetchData, params.order_id, params.order_number, params.tmp_token]);

  if (!order) {
    return <Wrapper></Wrapper>;
  }

  return (
    <Wrapper id="rev-AnonymousOrderDetailPage">
      <div className="content">
        <OrderDetail
          order={order}
          {...CustomCheckout.getExtraOrderPageProps()}
          supportReturn={false}
          refreshOrder={fetchData}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: 720px;
    min-height: var(--contentMinHeight);
    margin: 0 auto;
    padding: var(--basePadding);
  }
`;

export default withPageEntry(AnonymousOrderDetail);
